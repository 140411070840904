html, body {
  margin: 0;
  padding: 0;
  background-color: rgb(0, 0, 0);
  -webkit-animation: intro 1s ease-in;
          animation: intro 1s ease-in;
}
@-webkit-keyframes intro {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes intro {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
