html, body {
  margin: 0;
  padding: 0;
  background-color: rgb(0, 0, 0);
  animation: intro 1s ease-in;
}
@keyframes intro {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}